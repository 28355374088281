@tailwind base;
@tailwind components;
@tailwind utilities;
/* src/index.css or src/App.css */
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap');


body {
  margin: 0;
  font-family: 'Merriweather, Georgia, serif' !important;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.recommended-content .recommended-details {
  opacity: 0;
}

.recommended-content:hover .recommended-details {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
}

.carousel-container {
  margin-bottom: 20px;
}

.carousel-item {
  padding: 10px;
}

.custom-dot-list-style {
  margin-top: 20px;
}

.react-multiple-carousel__arrow {
  background-color: #0097f3 !important;
  border: none !important;


 
}


.react-multiple-carousel__arrow :hover {
  background: #732045 !important;
}


.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #000;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}
